<template>
  <div class="page-layout-wrapper">
    <div class="container">
      <PageHeader title="All Songs">
        <div class="mt-5">
          <router-link
            to="/songs/upload"
            class="btn btn-primary"
          >
            Upload Song
          </router-link>
        </div>
      </PageHeader>

      <SongList/>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/Page/PageHeader';
import SongList from '@/components/Songs/List';

export default {
  name: 'List',
  components: {
    PageHeader,
    SongList
  }
}
</script>
